import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  container,
  image,
  title,
  description,
  callToAction,
} from "./pageNotFound.module.css"

export default function PageNotFound() {
  const data = useStaticQuery(graphql`
    {
      content: file(relativePath: { eq: "page_not_found.md" }) {
        childMarkdownRemark {
          frontmatter {
            title
            description
            callToAction
          }
        }
      }
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
    }
  `)
  return (
    <div className={container}>
      <GatsbyImage
        image={data.logo.childImageSharp.gatsbyImageData}
        alt={data.content.childMarkdownRemark.frontmatter.title}
        className={image}
      />
      <h1 className={title}>
        {data.content.childMarkdownRemark.frontmatter.title}
      </h1>
      <p className={description}>
        {data.content.childMarkdownRemark.frontmatter.description}
      </p>
      <Link to="/" className={callToAction}>
        {data.content.childMarkdownRemark.frontmatter.callToAction}
      </Link>
    </div>
  )
}
