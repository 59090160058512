import React from "react"
import Container from "../components/container/container"
import PageNotFound from "../components/pageNotFound/pageNotFound"
import Footer from "../components/footer/footer"

export default function NotFound() {
  return (
    <Container>
      <PageNotFound />
      <Footer />
    </Container>
  )
}
