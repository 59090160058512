import React from "react"
import { container } from "./footer.module.css"
import { useStaticQuery, graphql } from "gatsby"

export default function Footer() {
  const data = useStaticQuery(graphql`
    {
      footer: file(relativePath: { eq: "footer.md" }) {
        childMarkdownRemark {
          frontmatter {
            description
          }
        }
      }
    }
  `)
  return (
    <div className={container}>
      <p>{data.footer.childMarkdownRemark.frontmatter.description}</p>
    </div>
  )
}
